import $ from "jquery";
import { DotLottie } from '@lottiefiles/dotlottie-web';

export default function initLottie() {
   const $lottie = $('#lottie-404');
   const dotLottie = $lottie.length && 
   new DotLottie({
    autoplay: true,
    loop: true,
    canvas: $lottie[0], // needs unwrapped DOM element so we add [0],
    src: $lottie.data('src')
});
}
