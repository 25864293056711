import $ from "jquery";

const $doc = $(document);
let svg = ` <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="17"
                height="10" viewBox="0 0 17 10" fill="none">
                <g clip-path="url(#clip0_8331_9823)">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M15.9483 0.928646C16.3428 1.32309 16.3428 1.96269 15.9483 2.35713L9.21432 9.09114C8.81974 9.48558 8.18028 9.48558 7.7857 9.09114L1.05169 2.35713C0.657248 1.96269 0.657248 1.32309 1.05169 0.928646C1.44627 0.534201 2.08573 0.534201 2.48018 0.928646L8.50004 6.94851L14.5199 0.928646C14.9144 0.534201 15.5539 0.534201 15.9484 0.928646H15.9483Z"
                        fill="#262D33" />
                </g>
                <defs>
                    <clipPath id="clip0_8331_9823">
                        <rect width="16" height="9.77778" fill="white" transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </svg>`;
// Dropdown initialization
export function initDropdown(dropdownSelector) {
  $(dropdownSelector).each(function () {
    const $dropdown = $(this);
    const $selectedOption = $dropdown.find(".selected-option");
    const $options = $dropdown.find(".dropdown-options");
    const $optionItems = $options.find(".dropdown-option");

    // Toggle dropdown on click
    $selectedOption.on("click", function () {
      $dropdown.toggleClass("open");
    });

    // Handle option selection
    $options.on("click", ".dropdown-option", function () {
      const value = $(this).data("value");
      const text = $(this).text();
      $selectedOption.text(text).data("value", value);
      $selectedOption.append(svg);
      $dropdown.removeClass("open");
      $dropdown.trigger("change", [value]);
    });

    // Handle keyboard navigation
    $selectedOption.on("keydown", function (e) {
      if (e.key === "Enter" || e.key === " ") {
        e.preventDefault();
        $dropdown.toggleClass("open");
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        $optionItems.first().focus();
      }
    });

    $optionItems.on("keydown", function (e) {
      const $focusedOption = $(this);

      if (e.key === "ArrowDown") {
        e.preventDefault();
        const $next = $focusedOption.next(".dropdown-option");
        if ($next.length) {
          $next.focus();
        }
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        const $prev = $focusedOption.prev(".dropdown-option");
        if ($prev.length) {
          $prev.focus();
        } else {
          $selectedOption.focus();
        }
      } else if (e.key === "Enter" || e.key === " ") {
        e.preventDefault();
        $focusedOption.click();
      } else if (e.key === "Escape") {
        e.preventDefault();
        $dropdown.removeClass("open");
        $selectedOption.focus();
      }
    });

    // Close dropdown if clicking outside
    $doc.on("click", function (e) {
      if (!$dropdown.is(e.target) && $dropdown.has(e.target).length === 0) {
        $dropdown.removeClass("open");
      }
    });
  });
}

document.addEventListener("DOMContentLoaded", function () {
  const params = new URLSearchParams(window.location.search);
  const activeTab = params.get("filtered");
  if (activeTab) {
    const tabButton = document.querySelector(`#${activeTab}`);
    if (tabButton) {
      tabButton.classList.add("archive__tab--active");
      tabButton.click();
    }
  }
});
