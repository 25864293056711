import { InspectorControls } from "@wordpress/block-editor";
import { PanelBody, Button } from "@wordpress/components";
import { useEffect, useState } from "@wordpress/element";
import { useSelect } from "@wordpress/data";
import $ from "jquery";
import "slick-carousel";

const SliderControls = ({ clientId }) => {
  const blockElement = useSelect(
    (select) => select("core/block-editor").getBlock(clientId),
    [clientId],
  );

  const [previewSlider, setPreviewSlider] = useState(false);

  const blockSelector = `[data-block="${clientId}"] .js-slider`;

  const destroySlider = () => {
    if (!previewSlider) {
      return;
    }
    $(blockSelector).slick("unslick");
    setPreviewSlider(false);
  };

  const initSlider = () => {
    if (previewSlider) {
      return;
    }
    $(blockSelector).slick({
      arrows: true,
      dots: false,
      fade: true,
      cssEase: "linear",
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
    });
    setPreviewSlider(true);
  };

  return (
    <InspectorControls>
      <PanelBody title="Slider Controls">
        <Button
          onClick={previewSlider ? destroySlider : initSlider}
          className={previewSlider ? "button-primary" : "button-secondary"}
        >
          {previewSlider ? "Stop Slider" : "Preview Slider"}
        </Button>
      </PanelBody>
    </InspectorControls>
  );
};

export default SliderControls;
